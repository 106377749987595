
import { Actions } from '@/store';
import { defineComponent } from 'vue';
import Back2Home from '@/components/Back2Home.vue';
import OverviewButton from '@/components/OverviewButton.vue';

export default defineComponent({
  name: 'Single Post',
  components: {
    Back2Home,
    OverviewButton,
  },
  beforeMount() {
    this.$store.dispatch(Actions.LoadPost, this.$route.params.id);
  },
  computed: {
    post() {
      return this.$store.state.singlePost;
    },
    postDate() {
      return new Date((this as any).post.date).toLocaleDateString('de-ch');
    },
  },
});
