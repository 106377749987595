<template>
  <div class="single-post" v-if="post && post.featuredImage">
    <div class="single-post__control">
      <back-2-home></back-2-home>
      <overview-button></overview-button>
    </div>
    <div class="single-post__date">{{ postDate }}</div>
    <div class="single-post__img-wrapper">
      <img
        class="single-post__img"
        :src="post.featuredImage.node.sourceUrl"
        :srcset="post.featuredImage.node.srcSet"
      />
    </div>
    <h1 class="single-post__title">{{ post.title }}</h1>
    <div class="single-post__content" v-html="post.content"></div>
  </div>
</template>

<script lang="ts">
import { Actions } from '@/store';
import { defineComponent } from 'vue';
import Back2Home from '@/components/Back2Home.vue';
import OverviewButton from '@/components/OverviewButton.vue';

export default defineComponent({
  name: 'Single Post',
  components: {
    Back2Home,
    OverviewButton,
  },
  beforeMount() {
    this.$store.dispatch(Actions.LoadPost, this.$route.params.id);
  },
  computed: {
    post() {
      return this.$store.state.singlePost;
    },
    postDate() {
      return new Date((this as any).post.date).toLocaleDateString('de-ch');
    },
  },
});
</script>

<style lang="scss">
.single-post {
  @include fluid-type($spacingBig, $spacingBig5, padding-bottom);

  margin-right: $spacingDefault;

  @include mq($from: tablet) {
    margin-right: 0;
  }
}
.single-post__img-wrapper {
  position: relative;
  min-width: 100%;

  height: 50vh;
  overflow: hidden;

  margin-right: -$spacingDefault;

  @include mq($from: tablet) {
    @include fluid-type(-$spacingRegular, -$spacingBig5, margin-left);
    @include fluid-type(-$spacingRegular, -$spacingBig5, margin-right);
  }
}

.single-post__img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.single-post__title {
  @include fluid-type($xxlFontSize, $xxxlFontSize);
}

.single-post__content {
  img {
    max-width: 100%;
    height: auto;
  }

  .wp-block-image {
    margin-left: 0;
    margin-right: 0;
  }
}

.single-post__control {
  display: flex;

  justify-content: space-between;
}

.single-post__date {
  text-align: right;

  @include fluid-type($spacingRegular, $spacingBig, padding-top);
  @include fluid-type($spacingRegular, $spacingBig, padding-bottom);
}
</style>
